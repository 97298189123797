


































































import Vue from 'vue';
import GetSafetyAnchorPointsBySiHaDocumentationRequestViewModel from '@/src/services/viewModel/request/SiHaSafetyAnchorPoint/GetSafetyAnchorPointsBySiHaDocumentationRequestViewModel';
import SafetyAnchorPointListResourceViewModel from '@/src/services/viewModel/resource/SafetyAnchorPointListResourceViewModel';
import SiHaDocumentationResourceViewModel from '@/src/services/viewModel/resource/SiHaDocumentationResourceViewModel';

export default Vue.extend({
  layout: 'layout-restricted-mobile-view',
  auth: true,
  data: () => ({
    sihaDocumentation: new SiHaDocumentationResourceViewModel(),
    isLoading: false,
    safetyAnchorPointList: [] as SafetyAnchorPointListResourceViewModel[],
  }),
  computed: {
    siHaDocumentationShareId() {
      return this.$router.currentRoute?.params?.shareId as string;
    },
  },
  async mounted() {
    await this.populateData();
  },
  methods: {
    async populateData() {
      this.isLoading = true;
      this.sihaDocumentation = await this.$service.api.siHaDocumentation.getSiHaDocumentationByShareId(this.siHaDocumentationShareId);
      const request = new GetSafetyAnchorPointsBySiHaDocumentationRequestViewModel();
      request.sihaDocumentationId = this.sihaDocumentation.id;
      const response = await this.$service.api.siHaSafetyAnchorPoint.getSafetyAnchorPointsBySiHaDocumentation(request);
      this.safetyAnchorPointList = response.items;
      this.isLoading = false;
    },
  },
});
